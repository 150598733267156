export default {
    name: 'Name',
    namear: 'Name AR',
    role: 'Permission role',
    login_id: 'UserName',
    password: 'Password',
    company_id: 'Company',
    remember_token: 'remember_token',
    email_verified_at: 'email_verified_at',
    email: 'Email',
    language: 'Language',
    status: 'Status',
    mobile: 'Mobile',
};
