

export default {
  q: '؟',
  sar: 'ريال',
  false: 'معطل',
  true: 'مفعل',
  progress: 'جارٍ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  done: 'موافق',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  enabled: 'مفعل',
  disabled: 'معطل',
  save: 'حفظ',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  store: 'إضافة',
  update: 'تعديل',
  upload: 'رفع',
  approval: 'تعميد',
  showhistory: 'عرض السجل',
  download: 'تحميل',
  control: 'تحكم',
  none: 'لا يوجد',
  file_desc: 'عنوان المرفق',
  file_name: 'اسم الملف',
  file_belong: 'مرفق تابع لـ',
  size: 'الحجم',
  files: 'مرفقات المستند',
  created_at: 'وقت الإنشاء',
  na: 'بدون',
  destroy: 'حذف',
  delete: 'حذف',
  show: 'عرض',
  post: 'ترحيل',
  subs: 'حقول الحالة',
  view: 'عرض',
  export: 'تصدير',
  close: 'إغلاق',
  edit: 'تعديل',
  add: 'إضافة',
  ref_date: 'تاريخ المرجع',
  add_bill: 'إضافة فاتورة',
  edit_bill: 'تعديل فاتورة',
  show_bill: 'عرض الفاتورة',
  add_Note: 'إضافة إشعار دائن/مدين',
  edit_Note: 'تعديل إشعار دائن/مدين',
  show_Note: 'عرض إشعار دائن/مدين',
  register: 'تسجيل',
  print: 'طباعة',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  the_search: 'البحث',
  exportPdf: 'تصدير البيانات PDF',
  exportExcel: 'تصدير البيانات Excel',
  ImportExcel: 'استيراد البيانات من Excel',
  refreshTable: 'تحديث الصفحة',
  refreshData: 'تحديث البيانات',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  year: 'سنة',
  showDetails: 'عرض التفاصيل',
  Details: 'تفاصيل ',
  information: 'معلومات ',
  the_information: 'المعلومات ',
  setting: 'الضبط',
  month: 'شهر',
  this_month: 'هذا الشهر',
  non_defined: 'غير محدد',
  greg_letter: ' م',
  hijri_letter: ' هـ',
  import: 'استيراد',
  attach_document:'المستندات',
  role: 'مجموعة الصلاحيات',
  all_notifications: 'عرض كل التنبيهات',
  multiple_upload: 'رفع ملفات',
}
