
export default {
  name: 'الاسم',
  name_hint: 'حقل الاسم مطلوب',
  name_ar: 'الاسم العربي',
  name_ar_hint: 'حقل الاسم العربي مطلوب',
  name_en: 'الاسم الإنجليزي',
  name_en_hint: 'حقل الاسم الإنجليزي مطلوب',
  login_id: 'اسم المسخدم',
  login_id_hint: 'حقل اسم المسخدم مطلوب',
  password: 'كلمة المرور',
  password_hint: 'حقل كلمة المرور مطلوب',
  password_confirmation: 'تأكيد كلمة المرور',
  password_confirmation_hint: 'حقل تأكيد كلمة المرور مطلوب',
  code: 'الكود',
  code_hint: 'حقل الكود مطلوب',
  age: 'العمر',
  age_hint: 'حقل العمر مطلوب',
  birth_date: 'تاريخ الميلاد',
  birth_date_hint: 'حقل تاريخ الميلاد مطلوب',
  mobile: 'رقم الجوال',
  mobile_hint: 'رقم الجوال مطلوب',
  sort_order: 'ترتيب العرض',
  active: 'فعال',
  percent: 'النسبة',
  status: 'الحالة',
  status_label: 'فعال',
  other: 'غير ذلك',
  false: 'معطل',
  true: 'مفعل',
  notes: 'ملاحظات',
  notes_hint: 'حقل ملاحظات مطلوب',
  menu_type1: 'قائمة رئيسية',
  menu_type2: 'قائمة فرعية',
  parent_id: 'فرعي من:',
  parent_id_hint: 'حقل فرعي من مطلوب',
  type:'النوع',
  type_hint:'حقل النوع مطلوب',
  phone: 'رقم الهاتف',
  phone_hint: 'حقل رقم الهاتف مطلوب',
  email: 'البريد الإلكتروني',
  email_hint: 'حقل البريد الإلكتروني مطلوب',
  contact_name: 'اسم الشخص',
  contact_name_hint: 'حقل اسم الشخص مطلوب',
  contact_mobile: 'رقم الجوال',
  contact_mobile_hint: 'حقل رقم الجوال مطلوب',
  contact_mobile2: 'رقم الجوال2',
  contact_mobile2_hint: 'حقل رقم الجوال2 مطلوب',
  contact_fax: 'رقم الفاكس',
  contact_fax_hint: 'حقل رقم الفاكس مطلوب',
  contact_phone: 'رقم الهاتف',
  contact_phone_hint: 'حقل رقم الهاتف مطلوب',
  contact_email: 'البريد الإلكتروني',
  contact_email_hint: 'حقل البريد الإلكتروني مطلوب',
  menu_url: 'رابط القائمة',
  menu_url_hint: 'حقل رابط القائمة مطلوب',
  perm: 'صلاحيات القائمة',
  perm_hint: 'حقل صلاحيات القائمة مطلوب',
  icon: 'ايقونة القائمة',
  icon_hint: 'حقل ايقونة القائمة مطلوب',
  system_type: 'تتبع لنظام',
  system_type_hint: 'حقل تتبع لنظام مطلوب',
  model_name: 'تتبع لموديول',
  model_name_hint: 'حقل تتبع لموديول مطلوب',
  role_id: 'محموعة الصلاحيات',
  role_id_hint: 'حقل محموعة الصلاحيات مطلوب',
  permission_id: 'معرف الصلاحية',
  permission_id_hint: 'حقل معرف الصلاحية مطلوب',
  title: 'العنوان',
  title_hint: 'حقل العنوان مطلوب',
}
