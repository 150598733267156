const Langsfiles = require.context('./', true, /\.js$/);
let LangsConsts = {};
for (const Langsfile of Langsfiles.keys()) {
  if(
      Langsfile.replace(/index.js/, '')
               .replace(/.js/, '')
               .replace(/^\.\//, '')
               .startsWith('attributes_')
  ){
    LangsConsts = {...LangsConsts, ...(Langsfiles(Langsfile).default || Langsfiles(Langsfile))};
  }
}
export default LangsConsts;
