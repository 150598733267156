export default {
  name: 'Name',
  name_hint: 'Name field required',
  name_ar: 'Arabic Name',
  name_ar_hint: 'Arabic Name field required',
  name_en: 'English Name',
  name_en_hint: 'English Name field required',
  login_id: 'UserName',
  login_id_hint: 'UserName field required',
  password: 'Password',
  password_hint: 'Password field required',
  password_confirmation: 'Password Confirmation',
  password_confirmation_hint: 'Password Confirmation field required',
  code: 'Code',
  code_hint: 'Code field required',
  age: 'Age',
  age_hint: 'Age field required',
  birth_date: 'Birth Date',
  birth_date_hint: 'Birth Date field required',
  mobile: 'Mobile',
  mobile_hint: 'Mobile field required',
  sort_order: 'Sort order',
  active: 'Enabled',
  percent: 'Percentage',
  status: 'Status',
  status_label: 'Status',
  other: 'Other',
  false: 'Disabled',
  true: 'Enabled',
  notes: 'Notes',
  notes_hint: 'Notes field required',
  menu_type1: 'Main Menu',
  menu_type2: 'Sub Menu',
  parent_id: 'Belongs To:',
  parent_id_hint: 'Belongs To field required',
  type:'Type',
  type_hint:'Type field required',
  phone: 'Phone',
  phone_hint: 'Phone field required',
  email: 'Email',
  email_hint: 'Email field required',
  contact_name: 'Contact Name',
  contact_name_hint: 'Contact Name field required',
  contact_mobile: 'Contact Mobile',
  contact_mobile_hint: 'Contact Mobile field required',
  contact_fax: 'Contact Fax',
  contact_fax_hint: 'Contact Fax field required',
  contact_phone: 'Contact Phone',
  contact_phone_hint: 'Contact Phone field required',
  contact_email: 'Email',
  contact_email_hint: 'Email field required',
  menu_url: 'Menu URL',
  menu_url_hint: 'Menu URL field required',
  perm: 'Permission',
  perm_hint: 'Permission field required',
  icon: 'Icon',
  icon_hint: 'Icon field required',
  system_type: 'Belongs to system:',
  system_type_hint: 'Belongs to system field required',
  model_name: 'Belongs to Module',
  model_name_hint: 'Belongs to Module field required',
  status_type: 'Status Type',
  status_type_hint: 'Status Type field required',
  role_id: 'Permission Role',
  permission_id: 'Permission ID',
}
