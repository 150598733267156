export default {
      name: 'الاسم',
    namear: 'الاسم العربي',
    role: 'الصلاحيات',
    login_id: 'اسم المستخدم',
    password: 'كلمة المرور',
    company_id: 'الشركة',
    remember_token: 'remember_token',
    email_verified_at: 'email_verified_at',
    email: 'البريد الإلكتروني',
    language: 'اللغة',
    status: 'الحالة',
    mobile: 'الجوال',
};
