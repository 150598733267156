export default {
    note_id:        'Note',
    note_date:      'Note Date',
    item_id:        'Item',
    client_id:      'Client',
    company_id:     'Company',
    qty:            'Qty',
    price:          'Price',
    total:          'Total',
    vat:            'VAT',
    vat_percentage: 'VAT Percentage',
    final_total:    'Final Total',
};
