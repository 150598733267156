export default {
    note_number:     'رقم الإشعار',
    note_date:       'تاريخ إنشاء الإشعار',
    ref_date:        'تاريخ المرجع',
    client_id:       'العميل',
    company_id:      'المنشئة',
    total:           'الاجمالي',
    vat:             'الضريبة',
    vat_percentage:  'نسبة الضريبة',
    final_total:     'الصافي',
    sign_id:         'الموقع',
    bank_id:         'المعلومات البنكية',
    posted:          'مرحل',
    none_posted:     'غير مرحل',
    posting:         'الترحيل',
};
