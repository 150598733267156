export default {
    proforma_bill_id:        'الفاتورة الأولية',
    bill_date:      'تاريخ الفاتورة',
    item_id:        'الصنف',
    client_id:      'العميل',
    company_id:     'المنشئة',
    qty:            'الكمية',
    price:          'السعر',
    total:          'الاجمالي',
    vat:            'الضريبة',
    vat_percentage: 'نسبة الضريبة',
    final_total:    'الصافي',
};
