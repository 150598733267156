export default {

    name_ar:    'الاسم بالعربي',
    name_en:    'الاسم بالانجليزي',
    phone:      'الهاتف',
    email:      'البريد الإلكتروني',
    mobile:     'الجوال',
    address:    'العنوان',
  extra_field:    'حقل إضافي',
    vatid:      'الرقم الضريبي',
    company_id: 'عميل لـ',
};
