

<template>
  <app-tooltip
      :disabled="tooltipDisabled"
      :text="getTooltip"
  >
    <v-btn
        v-bind="$attrs"
        v-on="$listeners"
        :class="getClasses"
        :min-width="minWidth"
        :style="cuStyles"
        fab
        small>
      <template v-if="create"><v-icon :style="cuStyles" color="success">add</v-icon></template>
      <template v-if="update"><v-icon :style="cuStyles" color="success">edit</v-icon></template>
      <template v-if="show"><v-icon :style="cuStyles" color="primary">visibility</v-icon></template>
      <template v-if="assign_claim"><v-icon :style="cuStyles" color="primary">mdi-palette</v-icon></template>
      <template v-if="upload"><v-icon :style="cuStyles" color="primary">insert_drive_file</v-icon></template>
      <template v-if="download"><v-icon :style="cuStyles" color="primary">mdi-arrow-down-bold-circle-outline</v-icon></template>
      <template v-if="showhistory"><v-icon :style="cuStyles" color="primary">mdi-post-outline</v-icon></template>
      <template v-if="destroy"><v-icon :style="cuStyles" color="error">delete</v-icon></template>
      <slot></slot>
    </v-btn>
  </app-tooltip>
</template>

<script>

export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    cuStyles: {
      type: [String],
      default: () => undefined
    },
    update: {
      type: Boolean,
      default: () => false
    },
    create: {
      type: Boolean,
      default: () => false
    },
    assign_claim: {
      type: Boolean,
      default: () => false
    },
    show: {
      type: Boolean,
      default: () => false
    },
    upload: {
      type: Boolean,
      default: () => false
    },
    download: {
      type: Boolean,
      default: () => false
    },
    showhistory: {
      type: Boolean,
      default: () => false
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'my-2'
    ].join(' '),
    getMinWidth() {
      return this.$attrs.icon === undefined ? this.minWidth : undefined;
    },
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.create) return this.$t('add');
      if(this.update) return this.$t('update');
      if(this.show) return this.$t('show');
      if(this.upload) return this.$t('files');
      if(this.download) return this.$t('download');
      if(this.showhistory) return this.$t('showhistory');
      if(this.assign_claim) return this.$t('assign');
      if(this.destroy) return this.$t('destroy');
      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  }
};
</script>
