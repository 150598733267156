import routes,{HOME_ROUTE_NAME}        from '@routes/config';
import __, {isEmpty} from "lodash";
import {lowerFirst}   from "lodash";
// import {} from "lodash";
// import {__} from "lodash";
// var _a = require("lodash");
__.mixin(require("lodash-inflection"));
const RouteAdminfiles = require.context('./', true, /\.js$/);
let RouteAdminConsts = [];
// let chkrouts = JSON.parse(localStorage.getItem('backend_routes'));
// let allowedperms = JSON.parse(localStorage.getItem('perms'));
// console.log(chkrouts)
if(routes){
    for (const RouteAdminfile of RouteAdminfiles.keys()) {
        if(
            RouteAdminfile.replace(/index.js/, '')
                          .replace(/.js/, '')
                          .replace(/^\.\//, '')
                          .startsWith('RoutesAdmin_')
        ) {

            // let chkname=lowerCase(RouteAdminfile.replace(/.js/, '').replace(/.RoutesAdmin_/, '').replace(/./, '').replace(/^\.\//, ''));
            // let addtoroutes = (RouteAdminfiles(RouteAdminfile).default || RouteAdminfiles(RouteAdminfile));
            let chkname=RouteAdminfile.replace(/.RoutesAdmin_/, '').replace(/.js/, '').replace(/./, '');
            // console.log(chkname);
            chkname=lowerFirst(chkname);
            for (const [key, value] of Object.entries(routes)) {
                let aa = __.pluralize(key);

                // console.log(chkname)
                // console.log(aa)
                // console.log(RouteAdminfile)
                if (chkname === aa) {
                    let addtoroutes = (RouteAdminfiles(RouteAdminfile).default ||
                        RouteAdminfiles(RouteAdminfile))

                    // console.log(RouteAdminfiles(RouteAdminfile).default)
                    // console.log(addtoroutes)
                    let add2 = {};
                    addtoroutes = addtoroutes.map(function (v) {
                        // console.log(v)
                        if(v.path !== undefined) {
                            RouteAdminConsts.push(v);
                        }
                    });
                    // if(!isEmpty(add2)){
                    //     RouteAdminConsts.push(add2);
                    // }
                }
            }
        }}
}
// console.log(RouteAdminConsts)
export default [
    {
        path: '',
        redirect: HOME_ROUTE_NAME,
        component: () => import( /* webpackChunkName: "App" */ '@views/App'),
        meta: {
            auth: true
        },
        children: [
            {
                path: routes.landing.home,
                name: routes.landing.home,
                component: () => import(/* webpackChunkName: "LandingHome" */ '@components/Home')
            },
            {
                path: 'profile',
                name: routes.user.profile,
                component: () => import(/* webpackChunkName: "Profile" */ '@components/user/Profile')
            },
            {
                path: routes.user.index,
                name: routes.user.index,
                component: () => import(/* webpackChunkName: "User" */ '@components/user/Index')
            },
            ...RouteAdminConsts
        ]
    },
];
