import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=47f027ce&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"
import style0 from "./Card.vue?vue&type=style&index=0&id=47f027ce&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f027ce",
  null
  
)

export default component.exports