export default {

    name_ar: 'Name AR',
    name_en: 'Name EN',
    phone: 'Phone',
    email: 'Email',
    mobile: 'Mobile',
    address: 'Address',
    extra_field: 'Extra Field',
    vatid: 'VAT ID',
    company_id: 'Client for ',
};
