

import API_CONFIG from '@/app/api-config';
import {defaultLocale} from '@/app/config';
import Auth from '@helpers/auth';
import routeAllies from '@routes/config';
// import axios from 'axios';
import axios from 'axios'
import Qs from 'qs';
import Vue from 'vue';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = API_CONFIG.baseUrl;
axios.defaults.headers.common['Locale'] = defaultLocale;
axios.defaults.headers.common['App-Name'] = process.env.MIX_APP_NAME;
axios.defaults.headers.common['App-Version'] = process.env.MIX_APP_VERSION;
axios.defaults.headers.common['S-Client'] = 'MHF';
axios.defaults.headers.common['Secret'] = 'jSdHSYKaOjLoJ4OT7i4bESG16dmKakGuYKaYKaOjLoJJmAoTxqWlyVEgGKaOjLoJ4OT7Sarh:MHF';

let token;
if ((token = document.head.querySelector('meta[name=\'csrf-token\']'))) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

let accessToken;
if ((accessToken = Auth.getAccessToken())) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}
axios.defaults.paramsSerializer = params => Qs.stringify(params, {arrayFormat: 'indices'});

// console.log(axios.defaults);

axios.interceptors.response.use(function (response) {
  const {data} = response || {};

  if (data) {
    response.result = data.data;
    if (data.message) {
      response.resultMessage = data.message
    }
  }
  return response;
}, function (error) {

  const {response} = error || {};
  const status = response && response.status || null;
  let url;

  if (status === 401) {
    if (window.location.pathname !== (url = routeAllies.auth.logout.path)) {
      window.location.href = url;
    }
  }
  if (status === 403) {
    if (window.location.pathname !== '/' && window.location.pathname !== '') {
      window.location.href = '/';
    }
  }
  if (response && response.data) {
    error.responseError = response.data;
  }
  return Promise.reject(error);
});

Vue.prototype.$axios = axios;
window.axios = axios;

export default axios;
