export default {
    bill_number:    'Invoice Number',
    bill_date:      'Invoice Create Date',
    ref_date:       'Reference Date',
    client_id:      'Client',
    company_id:     'Company',
    total:          'Total',
    vat:            'VAT',
    vat_percentage: 'VAT Percentage',
    final_total:    'Final Total',
    sign_id:        'Signed By',
    bank_id:        'Bank Details',
    posted:         'Posted',
    none_posted:    'None Posted',
    posting:         'Posting',
    po_id:           'PO No',
};
