

export default {
  q: '?',
  sar: 'Riyal',
  false: 'Disabled',
  true: 'Enabled',
  progress: 'progress ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'Login',
  home: 'Home',
  done: 'Done',
  logout: 'Logout',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enabled: 'Enabled',
  disabled: 'Disabled',
  save: 'Save',
  change: 'Change',
  options: 'options',
  create: 'create',
  store: 'Create',
  update: 'Update',
  upload: 'Upload',
  download: 'Download',
  control: 'control',
  none: 'None',
  na: 'Na',
  destroy: 'Delete',
  export: 'Export',
  close: 'Close',
  edit: 'Edit',
  add: 'Add',
  show: 'Show',
  post: 'Post',
  ref_date: 'Reference Date',
  add_bill: 'Add Invoice',
  edit_bill: 'Edit Invoice',
  show_bill: 'Show Invoice',
  add_Note: 'Add CR/DR Note',
  edit_Note: 'Edit CR/DR Note',
  show_Note: 'Show CR/DR Note',
  register: 'Register',
  print: 'Print',
  today: 'Today',
  day: 'Day',
  date: 'Date',
  now: 'Now',
  am: 'AM',
  pm: 'PM',
  error: 'Error !!',
  search: 'Search',
  the_search: 'Search',
  calculator: 'Calculator',
  mortgage: 'Mortgage',
  year: 'Year',
  showDetails: 'Show Details',
  Details: 'Details ',
  information: 'Information ',
  the_information: 'The Information ',
  setting: 'Setting',
  month: 'Month',
  subs: 'Status Fields',
  non_defined: 'Un-Defined',
  greg_letter: ' AD',
  hijri_letter: ' AH',
  import: 'Import',
  attach_document:'Documents',
  expansionPanelHeaderOptions: 'Tools',
};
