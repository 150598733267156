import axios from "axios";
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
const Resourcefiles = require.context('./resource', true, /\.js$/);
let ResourcesConsts = {};
for (const Resourcefile of Resourcefiles.keys()) {
  let Resourcename = Resourcefile
      .replace(/index.js/, '')
      .replace(/.js/, '')
      .replace(/^\.\//, '')
      .replace(/\.\w+$/, '')
  ResourcesConsts[Resourcename] = Resourcefiles(Resourcefile).default || Resourcefiles(Resourcefile);
}
export default {
  ResourcesConsts
}
