export default {

    name_ar:    'name AR',
    name_en:    'name EN',
    account_name:    'Beneficiary name',
    account_name_ar:    'Beneficiary name AR',
    account_name_en:    'Beneficiary name EN',
    address:    'Address',
    IBan:    'IBan',
    company_id: 'Company',
};
