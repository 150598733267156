export default {
    name_ar:        'الاسم بالعربي',
    name_en:        'الاسم بالانجليزي',
    account_name:    'اسم المستفيد',
    account_name_ar:    'اسم المستفيد بالعربي',
    account_name_en:    'اسم المستفيد بالانجليزي',
    address:    'العنوان',
    IBan:    'IBan',
    company_id:     'الشركة',
};
