import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import {isEmpty} from "lodash";
const RouteConfigfiles = require.context('./', true, /\.js$/);
let RouteConfigConsts = {};
let chkrouts = JSON.parse(localStorage.getItem('backend_routes'));
for (const RouteConfigfile of RouteConfigfiles.keys()) {
  if(
      RouteConfigfile.replace(/index.js/, '')
                     .replace(/.js/, '')
                     .replace(/^\.\//, '')
                     .startsWith('RoutesConfig_')
  ){
    let addtoroutes = (RouteConfigfiles(RouteConfigfile).default || RouteConfigfiles(RouteConfigfile));
    // console.log(chkrouts);
    if(chkrouts) {
      for (const [key, value] of Object.entries(addtoroutes)) {
        var thisobject={};
        for (const [key1, value1] of Object.entries(value)) {
          // console.log(value1);
          // console.log(chkrouts[value1]);
          if(chkrouts[value1]){
          }else{
            delete(value[key1]);
          }
        }
        if(isEmpty(addtoroutes[key])) delete( addtoroutes[key])
      }
      RouteConfigConsts = {...RouteConfigConsts, ...addtoroutes};
    }
  }
}
// console.log(RouteConfigConsts);
export const LOGIN_ROUTE_NAME = 'login';
export const LOGOUT_ROUTE_NAME = 'logout';
export const HOME_ROUTE_NAME = 'home';
export const USER_HOME_ROUTE_NAME = 'home';
const routeAllies = {
  landing: {
    home: HOME_ROUTE_NAME,
  },
  auth: {
    login: LOGIN_ROUTE_NAME,
    logout: {
      path: `/${LOGOUT_ROUTE_NAME}`,
      name: LOGOUT_ROUTE_NAME
    }
  },
  user: {
    profile: 'profile',
    index: 'user-index',
  },
  ...RouteConfigConsts
};
// console.log(routeAllies);
export default routeAllies;
